import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import {
  IUserGetDetailsReq,
  IUserPutDisableReq,
  IUserPutPasswordReq,
  IUserPutReq,
  TUserGetDetailsRep,
  TUserPutDisableRep,
  TUserPutPasswordRep,
  TUserPutRep
} from '../../lib-shared/api/client/User.Type';
import {
  IAddressDeleteIdReq,
  IAddressPostReq,
  IAddressPutIdDefaultReq,
  IAddressPutIdReq,
  TAddressDeleteIdRep,
  TAddressPostRep,
  TAddressPutIdDefaultRep,
  TAddressPutIdRep
} from '../../lib-shared/api/client/Address.Type';
import {
  IPaymentGetCardReq, IPaymentGetOrderIdReq,
  IPaymentPostSetupIntentReq,
  IPaymentPutCardDeleteReq,
  TPaymentGetCardRep, TPaymentGetOrderIdRep,
  TPaymentPostSetupIntentRep,
  TPaymentPutCardDeleteRep
} from '../../lib-shared/api/client/Payment.Type';
import {
  ISubscriptionGetDetailsReq,
  ISubscriptionPostReq,
  TSubscriptionGetDetailsRep,
  TSubscriptionPostRep
} from '../../lib-shared/api/client/Subscription.Type';
import {
  IOrderKitDeleteIdReq,
  IOrderKitGetShipLaterReq,
  IOrderKitPostOrderReq, TOrderKitDeleteIdRep, TOrderKitGetShipLaterRep,
  TOrderKitPostOrderRep
} from '../../lib-shared/api/client/OrderKit.Type';
import {
  TOrderGetIdDetailsReq,
  TOrderGetIdDetailsRep, TOrderGetRep,
  TOrderGetReq, IOrderPutIdPayReq, TOrderPutIdPayRep
} from '../../lib-shared/api/client/Order.Type';
import {
  TTransportSheetGetIsAvailableRep,
  ITransportSheetGetIsAvailableReq,
  TTransportSheetGetRep,
  TTransportSheetGetReq,
  TTransportSheetPostPdfRep,
  TTransportSheetPostPdfReq,
  TTransportSheetPutBookRep,
  ITransportSheetPutBookReq
} from '../../lib-shared/api/client/TransportSheet.Type';
import {
  IPickupGetReq, IPickupGetStockReq,
  TPickupGetRep, TPickupGetStockRep
} from '../../lib-shared/api/client/Pickup.Type';
import { IOrderLoyaltyGetReq, TOrderLoyaltyGetRep } from '../../lib-shared/api/client/OrderLoyalty.Type';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private url = ApiService.generateUrl(environment.api_url);

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) {
  }

  static generateUrl(base: string) {
    return `${base}/client`;
  }

  setUrl(base: string) {
    this.url = ApiService.generateUrl(base);
  }

  postAddress(params: IAddressPostReq) {
    return this.http.post<TAddressPostRep>(`${this.url}/address`, params, this.authService.getHttpOptions());
  }

  putAddressIdDefault(id: number, params: IAddressPutIdDefaultReq) {
    return this.http.put<TAddressPutIdDefaultRep>(`${this.url}/address/${id}/default`, null, this.authService.getHttpOptions());
  }

  putAddressId(addressId: number, params: IAddressPutIdReq) {
    return this.http.put<TAddressPutIdRep>(`${this.url}/address/${addressId}`, params, this.authService.getHttpOptions());
  }

  deleteAddressId(addressId: number, params: IAddressDeleteIdReq) {
    return this.http.delete<TAddressDeleteIdRep>(`${this.url}/address/${addressId}`, this.authService.getHttpOptions());
  }

  putUserPhoneValidate(params: { phoneCode: string }) {
    return this.http.put(`${this.url}/user/phonevalidate`, params, this.authService.getHttpOptions());
  }

  putUserPhoneCode() {
    return this.http.put(`${this.url}/user/phonecode`, null, this.authService.getHttpOptions());
  }

  putPassword(params: IUserPutPasswordReq) {
    return this.http.put<TUserPutPasswordRep>(`${this.url}/user/password`, params, this.authService.getHttpOptions());
  }

  putUserDisable(params: IUserPutDisableReq) {
    return this.http.put<TUserPutDisableRep>(`${this.url}/user/disable`, params, this.authService.getHttpOptions());
  }

  putUser(user: IUserPutReq) {
    return this.http.put<TUserPutRep>(`${this.url}/user`, user, this.authService.getHttpOptions());
  }

  getUserDetails(params: IUserGetDetailsReq) {
    return this.http.get<TUserGetDetailsRep>(`${this.url}/user/details`, this.authService.getHttpOptions());
  }

  getTimeSlots() {
    return this.http.get(`${this.url}/timeslot`, this.authService.getHttpOptions());
  }

  getTimeSlotsByAddress(addressId: number) {
    return this.http.get(`${this.url}/timeslot/${addressId}`, this.authService.getHttpOptions());
  }

  postTransportSheetPdf(params: TTransportSheetPostPdfReq): Observable<Blob & TTransportSheetPostPdfRep> {
    return this.http.post(`${this.url}/transportsheet/pdf`, params, {
      headers: this.authService.getHttpHeaders(),
      responseType: 'blob',
    });
  }

  putTransportSheetBook(params: ITransportSheetPutBookReq) {
    return this.http.put<TTransportSheetPutBookRep>(`${this.url}/transportsheet/book`, params, this.authService.getHttpOptions());
  }

  getTransportSheetIsAvailable(params: ITransportSheetGetIsAvailableReq) {
    return this.http.get<TTransportSheetGetIsAvailableRep>(`${this.url}/transportsheet/isavailable`, {
      headers: this.authService.getHttpHeaders(),
      // as any is here for retro compatibility with old version of angular
      params: new HttpParams({fromObject: params as any}),
    });
  }

  getTransportSheet(params: TTransportSheetGetReq) {
    return this.http.get<TTransportSheetGetRep>(`${this.url}/transportsheet`, this.authService.getHttpOptions());
  }

  putOrderIdPay(id: number, params: IOrderPutIdPayReq) {
    return this.http.put<TOrderPutIdPayRep>(`${this.url}/order/${id}/pay`, params, this.authService.getHttpOptions());
  }

  getOrderDetails(id: number, params: TOrderGetIdDetailsReq) {
    return this.http.get<TOrderGetIdDetailsRep>(`${this.url}/order/${id}/details`, this.authService.getHttpOptions());
  }

  postOrderKitOrder(params: IOrderKitPostOrderReq) {
    return this.http.post<TOrderKitPostOrderRep>(`${this.url}/orderkit/order`, params, this.authService.getHttpOptions());
  }

  getOrderKitShipLater(params: IOrderKitGetShipLaterReq) {
    return this.http.get<TOrderKitGetShipLaterRep>(`${this.url}/orderkit/shiplater`, this.authService.getHttpOptions());
  }

  deleteOrderKitShipLater(id: number, params: IOrderKitDeleteIdReq) {
    return this.http.delete<TOrderKitDeleteIdRep>(`${this.url}/orderkit/${id}`, this.authService.getHttpOptions());
  }

  getOrderLoyalty(params: IOrderLoyaltyGetReq) {
    return this.http.get<TOrderLoyaltyGetRep>(`${this.url}/orderloyalty`, this.authService.getHttpOptions());
  }

  getOrderList(params: TOrderGetReq) {
    return this.http.get<TOrderGetRep>(`${this.url}/order`, {
      headers: this.authService.getHttpHeaders(),
      // as any is here for retro compatibility with old version of angular
      params: new HttpParams({fromObject: params as any}),
    });
  }

  getArticleOptionList() {
    return this.http.get(`${this.url}/article/option`, this.authService.getHttpOptions());
  }

  getDiscountCode(params: { code: string; }) {
    return this.http.get(`${this.url}/discountcode`, {
      headers: this.authService.getHttpHeaders(), params: new HttpParams({fromObject: params})
    });
  }

  postPaymentSetupIntent(params: IPaymentPostSetupIntentReq) {
    return this.http.post<TPaymentPostSetupIntentRep>(`${this.url}/payment/setupintent`, params, this.authService.getHttpOptions());
  }

  putPaymentCardDelete(params: IPaymentPutCardDeleteReq) {
    return this.http.put<TPaymentPutCardDeleteRep>(`${this.url}/payment/card/delete`, params, this.authService.getHttpOptions());
  }

  getPaymentOrderId(orderId: number, params: IPaymentGetOrderIdReq) {
    return this.http.get<TPaymentGetOrderIdRep>(`${this.url}/payment/order/${orderId}`, this.authService.getHttpOptions());
  }

  getPaymentCardList(params: IPaymentGetCardReq) {
    return this.http.get<TPaymentGetCardRep>(`${this.url}/payment/card`, this.authService.getHttpOptions());
  }

  getIncidentTypeList() {
    return this.http.get(`${this.url}/incident/type`, this.authService.getHttpOptions());
  }

  getPdfInvoice(orderId: number) {
    return this.http.get(`${this.url}/pdf/invoice/${orderId}`, {
      headers: this.authService.getHttpHeaders(), responseType: 'blob'
    });
  }

  getPickupStock(params: IPickupGetStockReq) {
    const data = {
      addressId: params.addressId,
      'kitListKitId[]': params.kitList.map(k => k.kitId),
      'kitListQuantity[]': params.kitList.map(k => k.quantity),
    };

    return this.http.get<TPickupGetStockRep>(`${this.url}/pickup/stock`, {
      headers: this.authService.getHttpHeaders(),
      // as any is here for retro compatibility with old version of angular
      params: new HttpParams({fromObject: data as any}),
    });
  }

  getPickup(params: IPickupGetReq) {
    return this.http.get<TPickupGetRep>(`${this.url}/pickup`, {
      headers: this.authService.getHttpHeaders(),
      // as any is here for retro compatibility with old version of angular
      params: new HttpParams({fromObject: params as any}),
    });
  }

  getCredit() {
    return this.http.get(`${this.url}/credit`, this.authService.getHttpOptions());
  }

  getSponsorship(params: { code: string }) {
    return this.http.get(`${this.url}/sponsorship`, {
      headers: this.authService.getHttpHeaders(), params: new HttpParams({fromObject: params})
    });
  }

  postSubscription(params: ISubscriptionPostReq) {
    return this.http.post<TSubscriptionPostRep>(`${this.url}/subscription`, params, this.authService.getHttpOptions());
  }

  getSubscriptionDetails(params: ISubscriptionGetDetailsReq) {
    return this.http.get<TSubscriptionGetDetailsRep>(`${this.url}/subscription/details`, this.authService.getHttpOptions());
  }
}
